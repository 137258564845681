import React from "react";

// https://www.freecodecamp.org/forum/t/newline-in-react-string-solved/68484/18
export const addLineBreaks = string =>
  string.split("\n").map((text, index) => (
    <React.Fragment key={`${text}-${index}`}>
      {text}
      <br />
    </React.Fragment>
  ));
