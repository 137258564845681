import React from "react";
import PropTypes from "prop-types";

import { addLineBreaks } from "../utils/addLineBreaks";

export const HTMLContent = ({ content, className }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

const Content = ({ content, className }) => (
  <div className={className}>
    {typeof content === "string" ? addLineBreaks(content) : content}
  </div>
);

Content.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
